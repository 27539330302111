<template>
    <zj-scroll-list :title-list="titleList" :dataList="flowLoopDeviceList" :delay="1200" :limit="4" ></zj-scroll-list>
</template>

<script>
import ajax from '@/ajax.js'
import ZjScrollList from 'zj-scroll-list'
export default {
    name: 'ComEarlyWarnings',
    components: { 
        ZjScrollList,
    },
    data(){
        return {
            titleList: [
                { flex: 2, title: '序号'},
                { flex: 5, title: '任务名称'},
                { flex: 3, title: '状态'},
                { flex: 4, title: '操作'},
            ],
            flowLoopDevices: [],
        }
    },
	computed:{
		flowLoopDeviceList(){
            let list = [];
            list = this.flowLoopDevices.map( (it,index) => {
                // console.log("flowLoopDeviceList 1:", it)
            	return {
            		"key": "key1",
            		"row": [
                        {
                            "text": index+1,
                        },
            		    {
            		        // "icon": require("@/static/icons/icon-warning.svg"),
            		        // "icon_color": "green",
            		        "text": it.name,
            				"tip": it.name,
            		    },
            		    {
            		        "text": it.status,
            		        "text_color": it.status === '成功' ? "green" :　it.status === '警告' ? 'yellow' : it.status === '失败' ? 'red' : "gray",
            		    },
            		    {
            		        list: [
                                {
                                    "icon": require("@/static/icons/icon-hotspot-replay-active.svg"),
                                    "icon_color": "none",
                                    "tip": "运行",
                                    "emit": "mobile",
                                },
                                {
                                    "icon": require("@/static/icons/icon-hotspot-reset-active.svg"),
                                    "icon_color": "none",
                                    "tip": "重启",
                                    "emit": "video",
                                },
                                {
                                    "icon": require("@/static/icons/icon-hotspot-shut-active.svg"),
                                    "icon_color": "none",
                                    "tip": "关机",
                                    "emit": "file",
                                },
                            ]
            		    },
            		]
            	}
            });
            // console.log("flowLoopDeviceList 2:", list)
			return list;
		},
	},
	created(){
		this.download();
	},
	methods:{
		download(){
		    ajax({url: '/api/flow_loop',}).then(res => {
		        // console.log("download:", res);
		        this.flowLoopDevices = res;
		    })
		},
	},
	watch:{
		flowLoopDevices(val, old){
			// console.log("watch: flowLoopDevices:", val)
			// this.update();
		}
	},
	
}
</script>